@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");

html,
body,
#root {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
